export const templateHTMLPrint = ({
  bodyHTML,
  title = "",
  lang = "vi",
  appendStyle = false,
}) => {
  let domain = window.location.origin;
  if (domain === "http://localhost:8087") {
    domain = "https://partner.dev.hodo.app";
  }
  const styleCss = `
    <link rel="stylesheet" type="text/css" href="${domain}/css/css/bootstrap.min.css">
    <link rel="stylesheet" type="text/css" href="${domain}/css/css/bootstrap-vue.min.css">
    <link rel="stylesheet" type="text/css" href="${domain}/css/css/tailwindcss.css">
    <link rel="stylesheet" type="text/css" href="${domain}/css/css/print.css">
    <link rel="stylesheet" type="text/css" href="${domain}/css/css/print.module.css">
    <link rel="stylesheet" type="text/css" href="https://use.fontawesome.com/releases/v5.8.1/css/all.css">
  `;
  const template = `<html lang="${lang}"><head><meta charset="UTF-8"><meta http-equiv="X-UA-Compatible" content="IE=edge">
  <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
  <title>${title}</title>
  <link rel="preconnect" href="https://fonts.googleapis.com">
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
  <link href="https://fonts.googleapis.com/css2?family=Tinos:ital,wght@0,400;0,700;1,400;1,700&display=swap" rel="stylesheet">
  ${styleCss}
  ${appendStyle || ""}<style>
    /* vietnamese */
    .require-form .cs-title {
      width: auto !important;
    }
    .require-form .cs-list {
      gap: 30px;
    }
    .require-form .flex {
      display: flex;
      gap: 15px;
      flex-wrap: nowrap;
    }
    @font-face {
      font-family: "Times";
      font-weight: 700;
      src: url("https://dev.hodo.app/assets/font/tnr/bold.ttf");
    }
    @font-face {
      font-family: "Times";
      font-weight: 700;
      font-style: italic;
      src: url("https://dev.hodo.app/assets/font/tnr/bold-italic.ttf");
    }
    @font-face {
      font-family: "Times";
      font-weight: 400;
      src: url("https://dev.hodo.app/assets/font/tnr/regular.ttf");
    }
    @font-face {
      font-family: "Times";
      font-weight: 400;
      font-style: italic;
      src: url("https://dev.hodo.app/assets/font/tnr/regular-italic.ttf");
    }
    html, body {
      font-family: "Times New Roman", "Times", "Tinos", serif !important;
      font-size: 1.0625rem;
      line-height: 1.5rem;
      font-weight: 500;
      font-style: normal;
      letter-spacing: 0em;
      color: #000000 !important;
    }
    .hidden-on-pdf {
      display: none !important;
    }
    .hodo-print,
    .hodo-print * {
      font-family: "Times New Roman", "Times", "Tinos", serif !important;
      letter-spacing: 0em;
      color: #000000 !important;
    }
    .hodo-print img {
      max-width: 100%;
    }
    .hodo-print img,
    .hodo-print svg {
      vertical-align: middle;
    }
    .hodo-print .content-none {
      width: 100%;
      border-bottom: 2px dotted;
    }
    .hodo-print .font {
      font-family: "Times New Roman", Tinos, Times, serif !important;
    }
    .hodo-print p {
      margin: 0;
    }
    .hodo-print .text-italic {
      font-style: italic;
    }
    .hodo-print .px-8 {
      padding-right: 2rem;
      padding-left: 2rem;
    }
    .hodo-print .overflow-hidden {
      overflow: hidden;
    }
    .hodo-print .float-left {
      float: left;
    }
    .hodo-print .float-right {
      float: right;
    }
    .hodo-print .float-none {
      float: none;
    }
    .hodo-print .block {
      display: block;
    }
    .hodo-print .hidden {
      display: none;
    }
    .hodo-print .inline-block {
      display: inline-block !important;
    }
    .hodo-print .flex {
      display: flex;
    }
    .hodo-print .flex-col {
      flex-direction: column;
    }
    .hodo-print .align-top {
      vertical-align: top;
    }
    .hodo-print .text-center {
      text-align: center;
    }
    .hodo-print .text-left {
      text-align: left;
    }
    .hodo-print .text-right {
      text-align: right;
    }
    .hodo-print .normal-case {
      text-transform: none;
    }
    .hodo-print .uppercase {
      text-transform: uppercase;
    }
    .hodo-print .capitalize {
      text-transform: capitalize;
    }
    .hodo-print .leading-none {
      line-height: 1;
    }
    .hodo-print .align-middle {
      vertical-align: middle;
    }
    .hodo-print .justify-center {
      justify-content: center;
    }
    .hodo-print .justify-end {
      justify-content: end;
    }
    .hodo-print .justify-between {
      justify-content: space-between;
    }
    .hodo-print .items-start {
      align-items: flex-start;
    }
    .hodo-print .items-center {
      align-items: center;
    }
    .hodo-print .col-1 {
      width: 12% !important;
      display: inline-block !important;
      vertical-align: top;
    }
    .hodo-print .col-2 {
      width: 16.6666667% !important;
      display: inline-block !important;
      vertical-align: top;
    }
    .hodo-print .col-3 {
      width: 25% !important;
      display: inline-block !important;
      vertical-align: top;
    }
    .hodo-print .col-4 {
      width: 33.3333333% !important;
      display: inline-block !important;
      vertical-align: top;
    }
    .hodo-print .col-5 {
      width: 41% !important;
      display: inline-block !important;
      vertical-align: top;
    }
    .hodo-print .col-6 {
      width: 50% !important;
      display: inline-block !important;
      vertical-align: top;
    }
    .hodo-print .col-7 {
      width: 58.3333333% !important;
      display: inline-block !important;
      vertical-align: top;
    }
    .hodo-print .col-8 {
      width: 66.666666% !important;
      display: inline-block !important;
      vertical-align: top;
    }
    .hodo-print .col-12 {
      width: 100% !important;
      display: inline-block !important;
      vertical-align: top;
    }
    .hodo-print .w-full {
      width: 100% !important;
    }
    .hodo-print .w-half {
      width: 50% !important;
    }
    .hodo-print .w-one-third {
      width: 33.333% !important;
    }
    .hodo-print .w-two-thirds {
      width: 66.667% !important;
    }
    .hodo-print .font-normal {
      font-weight: 400;
    }
    .hodo-print .font-bold {
      font-weight: 700;
    }
    .hodo-print .fs-11,
    .hodo-print .fs-11 *,
    .hodo-print .content-size.fs-11,
    .hodo-print .content-size.fs-11 * {
      font-size: 0.6125rem;
      line-height: 0.75rem;
    }
    .hodo-print .text-xs,
    .hodo-print .text-xs *,
    .hodo-print .fs-12,
    .hodo-print .fs-12 *,
    .hodo-print .content-size.fs-12,
    .hodo-print .content-size.fs-12 * {
      font-size: 0.75rem;
      line-height: 1rem;
    }
    .hodo-print .fs-13,
    .hodo-print .fs-13 *,
    .hodo-print .content-size.fs-13,
    .hodo-print .content-size.fs-13 * {
      font-size: 0.8125rem;
      line-height: 1.25rem;
    }
    .hodo-print .fs-14,
    .hodo-print .fs-14 *,
    .hodo-print .content-size.fs-14,
    .hodo-print .content-size.fs-14 * {
      font-size: 0.875rem;
      line-height: 1.25rem;
    }
    .hodo-print .fs-15,
    .hodo-print .fs-15 *,
    .hodo-print .content-size.fs-15,
    .hodo-print .content-size.fs-15 * {
      font-size: 0.9375rem;
      line-height: 1.25rem;
    }
    .hodo-print .text-sm,
    .hodo-print .text-sm * .hodo-print .fs-16,
    .hodo-print .fs-16 *,
    .hodo-print .content-size.fs-16,
    .hodo-print .content-size.fs-16 * {
      font-size: 1rem;
      line-height: 1.25rem;
    }
    .hodo-print .text-base,
    .hodo-print .text-base * {
      font-size: 1rem;
      line-height: 1.5rem;
    }
    .hodo-print .fs-17,
    .hodo-print .fs-17 *,
    .hodo-print .content-size.fs-17,
    .hodo-print .content-size.fs-17 * {
      font-size: 1.0625rem;
      line-height: 1.5rem;
    }
    .hodo-print .text-lg,
    .hodo-print .text-lg *,
    .hodo-print .fs-18,
    .hodo-print .fs-18 *,
    .hodo-print .content-size.fs-18,
    .hodo-print .content-size.fs-18 * {
      font-size: 1.125rem;
      line-height: 1.75rem;
    }
    .hodo-print .fs-19,
    .hodo-print .fs-19 *,
    .hodo-print .content-size.fs-19,
    .hodo-print .content-size.fs-19 * {
      font-size: 1.1875rem;
      line-height: 1.75rem;
    }
    .hodo-print .text-xl,
    .hodo-print .text-xl *,
    .hodo-print .fs-20,
    .hodo-print .fs-20 *,
    .hodo-print .content-size.fs-20,
    .hodo-print .content-size.fs-20 * {
      font-size: 1.25rem;
      line-height: 1.75rem;
    }
    .hodo-print .fs-22,
    .hodo-print .fs-22 *,
    .hodo-print .content-size.fs-22,
    .hodo-print .content-size.fs-22 * {
      font-size: 1.375rem;
      line-height: 1.825rem;
    }
    .hodo-print .text-2xl,
    .hodo-print .text-2xl *,
    .hodo-print .fs-24,
    .hodo-print .fs-24 *,
    .hodo-print .content-size.fs-24,
    .hodo-print .content-size.fs-24 * {
      font-size: 1.5rem;
      line-height: 2rem;
    }
    .hodo-print .my-3 {
      margin-top: 0.75rem;
      margin-bottom: 0.75rem;
    }
    .hodo-print .px-1 {
      padding-left: 0.25rem;
      padding-right: 0.25rem;
    }
    .hodo-print .px-24 {
      padding-left: 6rem !important;
      padding-right: 6rem !important;
    }
    .hodo-print .py-0 {
      padding-top: 0;
      padding-bottom: 0;
    }
    .hodo-print .py-1 {
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;
    }
    .hodo-print .pt-12 {
      padding-top: 3rem;
    }
    .hodo-print .p-0 {
      padding: 0;
    }
    .hodo-print .p-1 {
      padding: 0.25rem;
    }
    .hodo-print .p-2 {
      padding: 0.5rem;
    }
    .hodo-print .pt-1 {
      padding-top: 0.25rem;
    }
    .hodo-print .pb-1 {
      padding-bottom: 0.25rem;
    }
    .hodo-print .pb-2 {
      padding-bottom: 0.5rem;
    }
    .hodo-print .pl-0 {
      padding-top: 0;
    }
    .hodo-print .pl-0\.5 {
      padding-left: 0.125rem;
    }
    .hodo-print .pl-2 {
      padding-left: 0.5rem;
    }
    .hodo-print .pt-3 {
      padding-top: 0.75rem;
    }
    .hodo-print .pl-3 {
      padding-left: 0.75rem !important;
    }
    .hodo-print .pl-12 {
      padding-left: 3rem;
    }
    .hodo-print .pl-16 {
      padding-left: 4rem;
    }
    .hodo-print .pl-1 {
      padding-left: 0.25rem;
    }
    .hodo-print .pr-0 {
      padding-right: 0;
    }
    .hodo-print .pr-12 {
      padding-right: 3rem;
    }
    .hodo-print .pr-16 {
      padding-right: 4rem;
    }
    .hodo-print .pr-20 {
      padding-right: 5rem;
    }
    .hodo-print .-mt-1 {
      margin-top: -0.25rem;
    }
    .hodo-print .mt-1 {
      margin-top: 0.25rem;
    }
    .hodo-print .mt-2 {
      margin-top: 0.5rem;
    }
    .hodo-print .mt-3 {
      margin-top: 0.75rem;
    }
    .hodo-print .mt-4 {
      margin-top: 1rem;
    }
    .hodo-print .m-6 {
      margin: 1.5rem;
    }
    .hodo-print .ml-6 {
      margin-left: 1.5rem;
    }
    .hodo-print .mt-6 {
      margin-top: 1.5rem;
    }
    .hodo-print .mt-8 {
      margin-top: 2rem;
    }
    .hodo-print .mt-10 {
      margin-top: 2.5rem;
    }
    .hodo-print .mt-12 {
      margin-top: 3rem;
    }
    .hodo-print .mt-16 {
      margin-top: 4rem;
    }
    .hodo-print .mb-16 {
      margin-bottom: 4rem;
    }
    .hodo-print .mt-20 {
      margin-top: 5rem;
    }
    .hodo-print .mt-24 {
      margin-top: 6rem;
    }
    .hodo-print .mt-44 {
      margin-top: 11rem;
    }
    .hodo-print .ml-auto {
      margin-left: auto;
    }
    .hodo-print .-ml-0\.25 {
      margin-left: -1.5px;
    }
    .hodo-print .mx-1 {
      margin-left: 0.25rem;
      margin-right: 0.25rem;
    }
    .hodo-print .ml-1 {
      margin-left: 0.25rem;
    }
    .hodo-print .ml-2 {
      margin-left: 0.5rem;
    }
    .hodo-print .ml-3 {
      margin-left: 0.75rem;
    }
    .hodo-print .ml-3\.5 {
      margin-left: 0.875rem;
    }
    .hodo-print .ml-4 {
      margin-left: 1rem;
    }
    .hodo-print .ml-5 {
      margin-left: 1.25rem;
    }
    .hodo-print .ml-24 {
      margin-left: 6rem;
    }
    .hodo-print .mr-0\.25 {
      margin-right: 1.5px;
    }
    .hodo-print .mr-1 {
      margin-right: 0.25rem;
    }
    .hodo-print .mr-3 {
      margin-right: 0.75rem;
    }
    .hodo-print .mb-0 {
      margin-bottom: 0;
    }
    .hodo-print .mb-0\.5 {
      margin-bottom: 0.125rem;
    }
    .hodo-print .mb-1 {
      margin-bottom: 0.25rem;
    }
    .hodo-print .mb-2 {
      margin-bottom: 0.5rem;
    }
    .hodo-print .mb-3 {
      margin-bottom: 0.75rem;
    }
    .hodo-print .mb-6 {
      margin-bottom: 1.5rem;
    }
    .hodo-print .mb-8 {
      margin-bottom: 2rem;
    }
    .hodo-print .hodo-table figure {
      margin: 0 0 1rem !important;
    }
    .hodo-print .hodo-table table {
      border-collapse: collapse !important;
      width: 100% !important;
      border: 1px solid #7d7d7e !important;
      margin-top: 1rem !important;
    }
    .hodo-print .hodo-table.border-black table {
      border-color: black !important;
    }
    .hodo-print .hodo-table table thead th {
      text-transform: uppercase !important;
    }
    .hodo-print .hodo-table table td,
    .hodo-print .hodo-table table th {
      padding: 8px !important;
      border: 1px solid #7d7d7e !important;
    }
    .hodo-print .hodo-table.border-black table td,
    .hodo-print .hodo-table.border-black table th {
      border-color: black !important;
    }

    .hodo-print .hodo-table table td p {
      min-height: 25px !important;
    }
    .hodo-table-cs tbody tr:nth-child(2) td:first-child {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }

    .hodo-table-cs tbody tr:nth-child(2) td:first-child p {
      margin-bottom:4px
    }

    .hodo-table-cs tbody tr:nth-child(2) td:nth-child(2) p,
    .hodo-table-cs tbody tr:nth-child(2) td:nth-child(3) p {
      min-height: 35px !important;
      min-width: 45px !important;
    }


    .hodo-print .table-width-full table {
      width: 100% !important;
    }
    .hodo-print .el-table--border,
    .hodo-print .el-table--group {
      border-top: 1px solid #000;
      border-right: 1px solid #000;
      border-left: 1px solid #000;
    }
    .hodo-print .el-table th.el-table__cell.is-leaf {
      border-right: 1px solid #000;
      border-bottom: 1px solid #000;
    }
    .hodo-print .el-table__row:last-child,
    .hodo-print .el-table__cell:last-child,
    .hodo-print .el-table th.el-table__cell.is-leaf:last-child {
      border-right: none;
    }
    .hodo-print .el-table__cell {
      border-bottom: 1px solid #000;
      border-right: 1px solid #000;
    }
    .hodo-print .el-table--border th.el-table__cell > div.cell {
      line-height: 1.25 !important;
    }
    .hodo-print thead th.padding-header {
      padding: 0 8px !important;
    }
    .hodo-print thead th.header-text-left {
      text-align: left !important;
      padding: 0 8px !important;
    }
    .hodo-print .break-words {
      overflow-wrap: break-word !important;
      word-break: break-word !important;
      word-wrap: break-word !important;
      white-space: break-spaces !important;
    }
    .hodo-print .border-box {
      border: 1px solid #000;
    }
    .hodo-print .border {
      border: 1px solid #e0e0e0 !important;
    }
    .hodo-print .border-t {
      border-top: 1px solid #000;
    }
    .hodo-print .border-b {
      border-bottom: 1px solid #000;
    }
    .hodo-print .border-b-cs {
      border-bottom: 1px solid #7d7d7e;
    }
    .hodo-print .border-l {
      border-left: 1px solid #000;
    }
    .hodo-print .border-r {
      border-right: 1px solid #000;
    }
    .relative {
      position: relative;
    }
    .absolute {
      position: absolute;
    }
    .top-0 {
      top: 0;
    }
    .right-0 {
      right: 0;
    }
    .bottom-0 {
      bottom: 0;
    }
    .left-0 {
      left: 0;
    }
    .whitespace-nowrap {
      white-space: nowrap;
    }
    .overflow-hidden {
      overflow: hidden;
    }
    .float-left {
      float: left;
    }
    .float-right {
      float: right;
    }
    .float-none {
      float: none;
    }
    .block {
      display: block;
    }
    .inline-block {
      display: inline-block;
    }
    .flex {
      display: flex;
    }
    .flex-col {
      flex-direction: column;
    }
    .align-top {
      vertical-align: top;
    }
    .text-center {
      text-align: center;
    }
    .text-left {
      text-align: left;
    }
    .text-right {
      text-align: right;
    }
    .normal-case {
      text-transform: none;
    }
    .uppercase {
      text-transform: uppercase;
    }
    .capitalize {
      text-transform: capitalize;
    }
    .leading-none {
      line-height: 1;
    }
    .align-middle {
      vertical-align: middle;
    }
    .justify-center {
      justify-content: center;
    }
    .justify-end {
      justify-content: end;
    }
    .justify-between {
      justify-content: space-between;
    }
    .items-start {
      align-items: flex-start;
    }
    .items-center {
      align-items: center;
    }
    .box-number {
      border: 1px solid black;
      height: 1.5rem !important;
    }
    .box-item {
      font-family: "Times New Roman", Tinos, Times, serif !important;
      height: 1.5rem !important;
      width: 1.5rem !important;
      border-right: 1px solid black;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .box-item-bhyt {
      font-family: "Times New Roman", Tinos, Times, serif !important;
      height: 1.5rem !important;
      min-width: 1.5rem !important;
      width: 100%;
      padding: 2px;
      border-right: 1px solid black;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .box-item-bhyt:last-child,
    .box-item:last-child {
      border-right: none;
    }
    .checkbox {
      display: inline-flex;
      cursor: pointer;
      position: relative;
    }
    .html2pdf__page-break {
      page-break-before: always;
    }
    .italic {
      font-style: italic;
    }
    .not-italic {
      font-style: normal;
    }
    .w-3 {
      width: 0.75rem;
    }
    .h-3 {
      height: 0.75rem;
    }
    .w-24 {
      width: 6rem;
    }
    .h-24 {
      height: 6rem;
    }
    .w-40 {
      width: 10rem;
    }
    .h-40 {
      height: 10rem;
    }
    .cursor-pointer {
      cursor: pointer;
    }
    .cursor-default {
      cursor: default;
    }
    .min-h-250 {
      min-height: 250px;
    }
    .min-h-350 {
      min-height: 350px;
    }
    .hodo-print .border-box-table {
      border-top: 1px solid black;
      border-left: 1px solid black;
    }
    .hodo-print .border-bottom-item-table {
      border-bottom: 1px solid black;
    }
   .hodo-print .border-right-item-table {
      border-right: 1px solid black;
    }
    .hodo-print .box-nv {
      position: relative;
      border: 1px solid black;
      margin-top: 4px;
    }

    .hodo-print .el-icon-close {
      position: absolute;
      right: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 0.75rem;
      height: 0.75rem;
      opacity: 1;
    }
    .hodo-print .el-icon-close:before, .el-icon-close:after {
      position: absolute;
      left: 1.1rem;
      content: ' ';
      height: 0.75rem;
      width: 2px;
      background-color: #333;
    }
    .hodo-print .el-icon-close:before {
      transform: rotate(45deg);
    }
    .hodo-print .el-icon-close:after {
      transform: rotate(-45deg);
    }x
    .hodo-print .el-center {
      position: absolute;
      content: "";
      right: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    .hodo-print .w-fit{
      width: fit-content;
    }
    .hodo-print .flex-1{
      flex: 1;
    }

    .hodo-print .ql-syntax {
      background-color: #23241f;
      color: #f8f8f2;
      overflow: visible;
    }

    .hodo-print .ql-align-center{
      text-align: center;
    }

    .hodo-print .ql-align-right{
      text-align: right;
    }

    .hodo-print .ql-align-left{
      text-align: left;
    }

    .object-contain{
      object-fit: contain;
    }

    .hodo-print .mx-auto {
      margin-left: auto;
      margin-right: auto;
    }

    .hodo-print .content-none-cs{
      margin-bottom: 0.25rem;
    }
    .cs-container {
      position: relative;
      overflow: hidden;
      padding: 20px;
      box-sizing: border-box;

      * {
        color: black;
        font-family: "Times New Roman", "Tinos", Times, serif !important;
        font-size: 18px;
      }

      #printOrderForm {
        margin: auto;
        // margin-top: 100px;
        padding-left: 40px;
        padding-right: 40px;
      }

      h2 {
        // color: black;
        font-size: 28px;
      }
    }

    .container-border {
      border: 1px solid #5c5c5c;
      padding: 0;
      width: 50%;
    }

    .cs-title {
      display: block;
      width: 100%;
      font-size: 24px;
      font-weight: 700;
      text-transform: uppercase;
      padding: 6px;
    }

    .cs-flex {
      display: flex;
      gap: 8px;
      padding: 6px;
      box-sizing: border-box;
      align-items: center;
    }

    .cs-border-b {
      border-bottom: 1px solid #5c5c5c;
    }
    .cs-border-t {
      border-top: 1px solid #5c5c5c;
    }

    .cs-border-l {
      border-left: 1px solid #5c5c5c;
    }

    .cs-border-r {
      border-right: 1px solid #5c5c5c;
    }

    .cs-width-full {
      width: 100% !important;
    }

    .cs-font-bold {
      font-weight: bold;
    }

    .cs-checkbox {
      pointer-events: none;
      display: flex;
      align-items: center;

      input {
        width: 18px;
        height: 18px;
      }

      input[type="checkbox"] {
        accent-color: #20419b;
      }

      label {
        font-size: 14px;
        font-weight: 400;
        margin: 0;
        margin-left: 6px;
      }
    }

    .cs-content {
      flex: 1;
    }

    .min-checkbox {
      min-width: 120px;
    }
    .box-content {
      display: block;
      padding: 6px;
      min-height: 120px;
    }
    .html2pdf__page-break {
      page-break-before: always;
    }

    .cs-padding {
      padding: 6px;
    }

    .cs-border {
      border: 1px solid #5c5c5c;
    }

    .content-none {
      width: 100%;
      border-bottom: 2px dashed;
    }

    .cs-text-sm {
      font-size: 11px;
      font-weight: 500;
    }

    .cs-text-md {
      font-size: 16px;
    }

    .cs-list {
      display: flex;
      gap: 12px;
    }

    .cs-ol {
      li {
        position: relative;
        padding-left: 10px;
        margin-left: 15px;
      }

      li:before {
        position: absolute;
        left: -2px;
      }
    }

    .close-icon {
      position: absolute;
      top: 10px;
      right: 20px;
      color: black;
      font-size: 40px;
      cursor: pointer;
    }

    .cs-img {
      width: 300px;
    }

    .title-color {
      color: rgb(196, 91, 19);
      font-size: 36px !important;
    }

    @media print {
      body,
      html {
        height: 100vh;
        *  {
          color: black !important;
        }
      }

      .hidden-print {
        border: none;
        outline: none;
        background: transparent;
      }

      #BrowserPrintDefaults {
        display: none;
      }

      #printOrderForm {
        max-width: 1000px !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
      }

      .html2pdf__page-break {
        page-break-before: always;
      }

      @page {
        margin: 0;
      }
    }
    .cs-container {
      position: relative;
      overflow: hidden;
      padding: 20px;
      box-sizing: border-box;

      * {
        color: black;
        font-family: "Times New Roman", "Tinos", Times, serif !important;
        font-size: 18px;
      }

      #printOrderForm {
        margin: auto;
        // margin-top: 100px;
        padding-left: 40px;
        padding-right: 40px;
      }

      .w-90 {
        width: 90%;
      }
      .w-80 {
        width: 80%;
      }
      .w-30 {
        width: 30%;
      }

      h2 {
        // color: black;
        font-size: 28px;
      }
    }

    .container-border {
      border: 1px solid #5c5c5c;
      padding: 0;
      width: 50%;
    }

    .cs-title {
      display: block;
      width: 100%;
      font-size: 24px;
      font-weight: 700;
      text-transform: uppercase;
      padding: 6px;
    }

    .cs-flex {
      display: flex;
      gap: 8px;
      padding: 6px;
      box-sizing: border-box;
      align-items: center;
      .flex-grow-4 {
        flex-grow: 4;
      }
      .flex-grow-2 {
        flex-grow: 2;
      }
      &.gap-0 {
        gap: 0px;
      }
    }

    // .cs-label {
    // width: 150px;
    // }
    .bold {
      font-weight: bold;
    }

    .cs-border-b {
      border-bottom: 1px solid #5c5c5c;
    }
    .cs-border-t {
      border-top: 1px solid #5c5c5c;
    }

    .cs-border-l {
      border-left: 1px solid #5c5c5c;
    }

    .cs-border-r {
      border-right: 1px solid #5c5c5c;
    }

    .cs-width-full {
      width: 100% !important;
    }

    .cs-font-bold {
      font-weight: bold;
    }

    .cs-checkbox {
      pointer-events: none;
      display: flex;
      align-items: baseline;
      input {
        transform: translateY(3px);
        width: 18px;
        height: 18px;
      }

      input[type="checkbox"] {
        accent-color: #20419b;
      }

      label {
        font-size: 14px;
        font-weight: 400;
        margin: 0;
        margin-left: 6px;
      }
    }

    .cs-content {
      flex: 1;
    }

    .min-checkbox {
      min-width: 120px;
    }
    .box-content {
      display: block;
      padding: 6px;
      min-height: 120px;
    }
    .html2pdf__page-break {
      page-break-before: always;
    }

    .cs-padding {
      padding: 6px;
    }

    .cs-border {
      border: 1px solid #5c5c5c;
    }

    .content-none {
      width: 100%;
      border-bottom: 2px dashed;
    }

    .cs-text-sm {
      font-size: 11px;
      font-weight: 500;
    }

    .cs-text-md {
      font-size: 16px;
    }

    .cs-list {
      display: flex;
      gap: 12px;
    }

    .cs-ol {
      li {
        position: relative;
        padding-left: 10px;
        margin-left: 15px;
      }

      li:before {
        position: absolute;
        left: -2px;
      }
    }
    .mh-100 {
      min-height: 100px;
    }
    .close-icon {
      position: absolute;
      top: 10px;
      right: 20px;
      color: black;
      font-size: 40px;
      cursor: pointer;
    }

    .cs-img {
      width: 300px;
    }

    .title-color {
      color: #0569c5;
      font-size: 32px !important;
    }

    @media print {
      body,
      html {
        height: 100vh;
        *  {
          color: black !important;
        }
      }
      .text-white {
        color: white !important;
      }

      .hidden-print {
        border: none;
        outline: none;
        background: transparent;
      }

      #BrowserPrintDefaults {
        display: none;
      }

      #printOrderForm {
        max-width: 1000px !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
      }

      .html2pdf__page-break {
        page-break-before: always;
      }

      @page {
        margin: 0;
      }
    }
    </style></head><body>${bodyHTML}</body></html>`;
  return template;
};

export const base64 = () => ({
  decode: (s) => window.btoa(unescape(encodeURIComponent(s))),
  encode: (b) => decodeURIComponent(escape(window.atob(b))),
});

export const exportPdfCaptureImage = async ({
  refDocument,
  fileName,
  path,
  type,
  type_id,
  uuid,
}) => {
  if (!refDocument || typeof refDocument === "undefined") {
    console.log("Refs document print not found");
    return;
  }

  try {
    await Promise.all(
      refDocument?.map(async (doc) => {
        const imgBase64Data = await this.captureImageCanvas({
          doc,
          dpi: 2000,
          scale: 10,
        });

        const res = await uploadImagePrint.fileUploadHandler(imgBase64Data);

        const wrap = document.createElement("div");
        const div = document.createElement("div");
        const img = document.createElement("img");

        img.src = res;
        img.style.maxWidth = "100%";
        div.appendChild(img);
        wrap.appendChild(div);

        return wrap.innerHTML;
      })
    ).then(async (res) => {
      let htmlRaw = "";

      console.log(res);

      // eslint-disable-next-line no-unused-expressions
      res?.map((img, index) => {
        if (index < res.length - 1) {
          htmlRaw += `${img}<div class="html2pdf__page-break"></div>`;
        } else {
          htmlRaw += img;
        }
      });

      const tempHTML = appUtils.templateHTMLPrint({
        bodyHTML: `<div class="hodo-print">${htmlRaw}</div>`,
      });
      const base64Data = appUtils.base64.decode(tempHTML);

      console.log(tempHTML);

      await this.savePdfToS3({
        base64: base64Data,
        fileName,
        path,
        type,
        type_id,
        uuid,
      });
    });
  } catch (error) {
    console.log(error);
  }
};
